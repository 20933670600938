<template>
  <v-app>
    <router-view />
  </v-app>
</template>
<style>
.cursorPointer {
  cursor: pointer !important;
}

.hover_SelectedItem:hover {
  background-color: #96bfe9;
}

.heading0 {
  font-size: 35px !important;
  font-weight: bolder !important;
  line-height: 35px !important;
}

.heading1 {
  font-size: 20px !important;
  font-weight: bold;
}

.heading4 {
  font-size: 25px !important;
}

.heading5 {
  font-size: 20px !important;
}

.heading6 {
  font-size: 16px !important;
}

.PrimaryFontColor {
  color: #3d83f7 !important;
}

.maxWidth {
  max-width: 120px !important;
}

.maxWidthVariant1 {
  max-width: 250px !important;
}

.maxWidthVariant2 {
  max-width: 120px !important;
}

.maxWidthVariant3 {
  max-width: 375px !important;
}

.maxWidthVariant4 {
  max-width: 92% !important;
}

/* .field_height.v-text-field .v-input__control .v-input__slot {
  min-height: 12px !important;
  display: flex !important;
  align-items: center !important;
} */

.field_height .v-field {
  min-height: 12px !important;
  max-height: 32px !important;

  display: flex !important;
  align-items: center !important;
}

/* .feild_width1 .v-field {
  width: 10px !important;
} */

.field_label_size.v-input .v-label {
  font-size: 12px !important;
  opacity: 1 !important;
}

.field_font .v-field__input {
  font-size: 12px !important;
}

.searchFieldWidth .v-field {
  width: 1px !important;
}

.v-select
  .v-input__control
  .v-input__slot
  .v-select__slot
  .v-select__selections {
  padding: 0 !important;
  min-height: 0 !important;
}

.v-select-list .v-list-item {
  min-height: 0 !important;
}

.v-select-list .v-list-item .v-list-item__content {
  padding: 14px 0 !important;
}

.FontSize {
  font-size: 12px !important;
}

.FontSizeVariant1 {
  font-size: 8px !important;
}

.FontSizeVariant2 {
  font-size: 10px !important;
}

.FontSizeVariant3 {
  font-size: 15px !important;
}

.FontSizeVariant4 {
  font-size: 12px !important;
}

.FontSizeVariant5 {
  font-size: 9px !important;
}

.FontSizeVariant6 {
  font-size: 35px !important;
  font-weight: bold;
  line-height: 40px;
}

.FontSizeVariant7 {
  font-size: 20px !important;
}

.FontSizeVariant8 {
  font-size: 18px !important;
}

.FontSizeVariant9 {
  font-size: 14px !important;
}

.upload-image-input {
  display: none;
  z-index: -9999;
}

.boldFontSize1 {
  font-size: 14px !important;
}

.borderRadiusVariant0 {
  border-radius: 0px !important;
}

.borderRadiusVariant1 {
  border-radius: 5px !important;
}

.borderRadiusVariant2 {
  border-radius: 10px !important;
}

.borderVariant0 {
  border-top: 1px solid black !important;
  border-left: 1px solid black !important;
  border-right: 1px solid black !important;
}

.borderVariant1 {
  border-bottom: 1px solid black !important;
}

.borderVariant2 {
  border: 1px solid black !important;
}

.borderVariant3 {
  border-top: 1px solid black !important;
}

.borderVariant4 {
  border-left: 1px solid black !important;
}

.borderRightVariant1 {
  border-right: 3px solid #303956 !important;
}

.paddingVariantOne {
  margin-left: 90px !important;
}

.talk-bubble {
  margin: 40px;
  display: inline-block;
  position: relative;
  width: auto;
  height: auto;
  background-color: rgb(224, 255, 244);
}

.tri-right.border.right-top:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -40px;
  right: auto;
  top: -8px;
  bottom: auto;
  border: 32px solid;
  border-color: #666 transparent transparent transparent;
}

.tri-right.right-top:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -15px;
  top: 0px;
  bottom: auto;
  border: 20px solid;
  border-color: #d4e1ff transparent transparent transparent;
}

.tri-left.border.left-top:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -40px;
  right: auto;
  top: -8px;
  bottom: auto;
  border: 32px solid;
  border-color: #666 transparent transparent transparent;
}

.tri-left.left-top:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -15px;
  right: auto;
  top: 0px;
  bottom: auto;
  border: 20px solid;
  border-color: #d4e1ff transparent transparent transparent;
}

.talktext {
  padding: 1em;
  line-height: 0em;
}

.select-file-input {
  display: none;
  z-index: -9999;
}

.excel-upload-input {
  display: none;
  z-index: -9999;
}

/* *********************TABLE CLASS VARIANT 1 */
.tableClassVariant1 table {
  border-collapse: separate;
  border-spacing: 0 0px !important;
}

.tableClassVariant1 th {
  height: 24px !important;
  /* text-align: center; */
  border: 1px solid #d5d7dd;
  padding: 5px;
  font-size: 12px !important;
}

.tableClassVariant1.v-table td {
  height: 24px !important;
  font-weight: 400 !important;
  font-size: 12px !important;
}

.tableClassVariant1 th {
  background-image: linear-gradient(#f6faff, #d5d7dd);
  color: #8e8484 !important;
  border-bottom: 1px solid #d5d7dd !important;
}

.tableClassVariant1 table th:first-child {
  border-radius: 4px 0 0 4px;
}

.tableClassVariant1 table th:last-child {
  border-radius: 0px 0px 0px 0px;
}

.tableClassVariant1 td:first-child {
  border-radius: 0px 0px 0px 0px;
}

.tableClassVariant1 td:last-child {
  border-radius: 0px 6px 6px 0px;
}

.tableClassVariant1 tr:nth-child(even) {
  background-color: #f6faff !important;
}

/* ************************************************************ */

.tableClassVariant2 table {
  border-collapse: separate;
  border-spacing: 0 0px !important;
}

.tableClassVariant2 th {
  /* min-width: 200px !important; */
  height: 24px !important;
  border: 1px solid #d5d7dd;
  padding: 5px;
  font-size: 12px !important;
  background-image: linear-gradient(#f6faff, #d5d7dd);
  color: #8e8484 !important;
  border-bottom: 1px solid #d5d7dd !important;
}

.tableClassVariant3 th {
  height: 30px !important;
  /* Height of the table header */
  padding: 5px;
  font-size: 12px !important;
  /* Font size of the text */
}

.tableClassVariant2 td {
  height: 24px !important;
  font-weight: 400 !important;
  font-size: 12px !important;
}

.tableClassVariant2 table th:first-child {
  border-radius: 4px 0 0 4px;
}

.tableClassVariant2 table th:last-child {
  border-radius: 0px 0px 0px 0px;
}

.tableClassVariant2 td:first-child {
  border-radius: 0px 0px 0px 0px;
}

.tableClassVariant2 td:last-child {
  border-radius: 0px 6px 6px 0px;
}

.tableClassVariant2 tr:nth-child(even) {
  background-color: #f6faff !important;
}



.image-upload-input {
  display: none;
  z-index: -9999;
}

.step_padding {
  padding-top: 5.7px !important;
  padding-bottom: 5.7px !important;
}

/* .chatFont {
  font-family: Coco !important;
} */
.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom_prepend .v-list-item__prepend > .v-badge .v-icon,
.v-list-item__prepend > .v-icon,
.v-list-item__append > .v-badge .v-icon,
.v-list-item__append > .v-icon {
  opacity: 1 !important;
}

.v-card-subtitle {
  opacity: 1 !important;
}
</style>
